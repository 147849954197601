import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import Axios from "axios";

Vue.config.productionTip = false;

// Let's see if this works now...
const axiosInstance = Axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_PATH}/api`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("pb_jwt");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosInstance;

Vue.filter("striphtml", function (value) {
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
