import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login.vue"),
    },
    {
      path: "/onboarding",
      name: "onboarding",
      component: () => import("./views/Onboarding.vue"),
    },
    {
      path: "/",
      name: "dashboard",
      component: () => import("./views/Dashboard.vue"),
      meta: {
        requiresAuth: true,
        breadCrumb: [],
      },
    },
    {
      path: "/clients",
      name: "clients",
      component: () => import("./views/Clients.vue"),
      meta: {
        requiresAuth: true,
        breadCrumb: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Clients",
            disabled: true,
            href: "",
          },
        ],
      },
    },
    {
      path: "/campaigns",
      name: "campaigns",
      component: () => import("./views/Campaigns.vue"),
      meta: {
        requiresAuth: true,
        breadCrumb: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Campaigns",
            disabled: true,
            href: "",
          },
        ],
      },
    },
    {
      path: "/prospects",
      name: "prospects",
      component: () => import("./views/Prospects.vue"),
      meta: {
        requiresAuth: true,
        breadCrumb: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Prospects",
            disabled: true,
            href: "",
          },
        ],
      },
    },
    {
      path: "/clients/:clientId",
      name: "client",
      component: () => import("./views/Client.vue"),
      meta: {
        requiresAuth: true,
        breadCrumb: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Clients",
            disabled: false,
            href: "/clients",
          },
          {
            text: "...",
            disabled: true,
            href: "",
          },
        ],
      },
    },
    {
      path: "/campaigns/:campaignId",
      name: "campaign",
      component: () => import("./views/Campaign.vue"),
      meta: {
        requiresAuth: true,
        breadCrumb: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Campaigns",
            disabled: false,
            href: "/campaigns",
          },
          {
            text: "...",
            disabled: true,
            href: "",
          },
        ],
      },
    },
    {
      path: "/prospects/:prospectId",
      name: "prospect",
      component: () => import("./views/Prospect.vue"),
      meta: {
        requiresAuth: true,
        breadCrumb: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Prospects",
            disabled: false,
            href: "/prospects",
          },
          {
            text: "...",
            disabled: true,
            href: "",
          },
        ],
      },
    },
    {
      path: "/senders",
      name: "senders",
      component: () => import("./views/Senders.vue"),
      meta: {
        requiresAuth: true,
        breadCrumb: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Senders",
            disabled: true,
            href: "/senders",
          },
        ],
      },
    },
    {
      path: "/senders/:domainId",
      name: "sender",
      component: () => import("./views/Sender.vue"),
      meta: {
        requiresAuth: true,
        breadCrumb: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Senders",
            disabled: false,
            href: "/senders",
          },
          {
            text: "...",
            disabled: true,
            href: "",
          },
        ],
      },
    },
    {
      path: "/replies",
      name: "replies",
      component: () => import("./views/Replies.vue"),
      meta: {
        requiresAuth: true,
        breadCrumb: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Replies",
            disabled: true,
            href: "/replies",
          },
        ],
      },
    },
    {
      path: "/leads",
      name: "leads",
      component: () => import("./views/Leads.vue"),
      meta: {
        requiresAuth: true,
        breadCrumb: [
          {
            text: "Dashboard",
            disabled: false,
            href: "/",
          },
          {
            text: "Leads",
            disabled: true,
            href: "/leads",
          },
        ],
      },
    },
  ],
});

router.beforeEach((to, _, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("pb_jwt") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (to.matched.some((record) => record.meta.isAdmin)) {
        const isAdmin = JSON.parse(localStorage.getItem("user")).is_admin;
        if (isAdmin) {
          next();
        } else {
          next({ name: "dashboard" });
        }
      }
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("pb_jwt") == null) {
      next();
    } else {
      next({ name: "dashboard" });
    }
  } else {
    next();
  }
});

export default router;
