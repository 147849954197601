<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer dark app :mini-variant="mini" permanent>
        <v-list id="main-menu-list">
          <v-list-item to="/" link>
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/prospects" link>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-check-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Prospects</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/senders" link>
            <v-list-item-icon>
              <v-icon>mdi-at</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Senders</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/campaigns" link>
            <v-list-item-icon>
              <v-icon>mdi-bullhorn-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Campaigns</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/replies" link>
            <v-list-item-icon>
              <v-icon>mdi-message-reply-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Replies</v-list-item-title>
              <v-badge
                v-if="replyCount > 0"
                :content="replyCount"
                offset-x="35"
                offset-y="0"
              ></v-badge>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/leads" link>
            <v-list-item-icon>
              <v-icon>mdi-account-cash-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Leads</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group :value="true" prepend-icon="mdi-cog" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Administration</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item to="/clients" link>
              <v-list-item-content>
                <v-list-item-title>View all clients</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/support" link>
              <v-list-item-content>
                <v-list-item-title
                  >Support Inbox
                  <v-badge
                    v-if="supportCount > 0"
                    :content="supportCount"
                    offset-x="-35"
                    offset-y="3.5"
                  ></v-badge
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-toolbar id="main-toolbar" flat>
          <v-app-bar-nav-icon @click="mini = !mini"></v-app-bar-nav-icon>
          <v-spacer />
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  dot
                  overlap
                  offset-y="20"
                  offset-x="20"
                  color="red darken-2"
                >
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon> mdi-message-outline </v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <v-card width="450">
                <v-list>
                  <v-subheader>Support Inbox</v-subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Can't add my colleague as a user
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Hi, I'm trying to add xxx@example.com as a user, but the
                        system won't let me.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Problem with card payment
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        My card gets declined when I'm trying to pay. Please
                        assist.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <!--v-menu offset-y offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  dot
                  overlap
                  offset-y="20"
                  offset-x="20"
                  color="red darken-2"
                >
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon> mdi-bell-outline </v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <v-card width="450">
                <v-list>
                  <v-subheader>Notifications</v-subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Reply to campaign email
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        The prospect Carl Lundgren replied to a message.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Campaign has finished
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        The campaign Marketing 1-25 April 22 has finished.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Potential problem with sender
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Emails from hello@example.com failed to deliver 3 times
                        in a row.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu-->
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon> mdi-account-arrow-down-outline </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-subheader>Clients</v-subheader>
                <v-list-item-group>
                  <v-list-item
                    v-for="client in allClients"
                    :key="client._id"
                    @click="store.selectedClient = client._id"
                    :class="
                      store.selectedClient === client._id
                        ? 'v-item--active v-list-item--active'
                        : ''
                    "
                  >
                    <v-list-item-title>
                      {{ client.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-btn icon @click="logout()">
              <v-icon> mdi-power </v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-container class="py-1 px-6" fluid>
          <v-row>
            <v-spacer />
            <v-breadcrumbs :items="$route.meta.breadCrumb"></v-breadcrumbs>
          </v-row>
          <v-row>
            <v-col cols="12">
              <router-view />
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { store } from "@/state/store.js";

export default {
  data: () => ({
    mini: false,
    allClients: undefined,
    replyCount: 0,
    supportCount: 2,
    store,
  }),
  async created() {
    try {
      const clientsResp = await this.$http.get("/clients");

      this.allClients = clientsResp.data.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );

      this.store.selectedClient =
        localStorage.getItem("pb_selectedClient") || this.allClients[0]._id;

      const replyCountResp = await this.$http.get(
        `/replies/${this.selectedClient}/count`
      );
      this.replyCount = replyCountResp.data.replyCount;
    } catch (err) {
      console.error(err);
    } finally {
      this.dataLoading = false;
    }
  },
  computed: {
    userId() {
      return JSON.parse(localStorage.getItem("user"))._id;
    },
    selectedClient() {
      return this.store.selectedClient;
    },
  },
  watch: {
    selectedClient(clientId) {
      localStorage.setItem("pb_selectedClient", clientId);
    },
  },
  methods: {
    goDashboard() {
      this.$router.push("/");
    },
    isAdmin() {
      const isAdmin = JSON.parse(localStorage.getItem("user")).is_admin;
      return isAdmin;
    },
    logout() {
      localStorage.removeItem("pb_jwt");
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#main-menu-list {
  padding: 0;
  > .v-list-item {
    height: 64px;
  }
  .v-list-item__title {
    text-align: left;
  }

  .v-list-group--active {
    &.primary--text {
      color: inherit !important;
      caret-color: inherit !important;
    }
  }

  .v-list-group__items {
    > a > .v-list-item__content > .v-list-item__title {
      font-size: 14px;
    }
  }
}

#main-toolbar {
  // height: 56px !important;
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0;
  z-index: 2;
  .main-client-selection {
    align-self: flex-end;
    width: 300px;
  }
}
</style>
