<template>
  <v-app>
    <main-layout v-if="!noLayout()"></main-layout>
    <no-layout v-else></no-layout>
  </v-app>
</template>

<script>
import MainLayout from "./layouts/MainLayout";
import NoLayout from "./layouts/NoLayout";

export default {
  data: () => ({
    mini: false,
  }),
  methods: {
    noLayout() {
      const routesWithoutLayout = ["/login", "/onboarding"];
      return routesWithoutLayout.includes(this.$route.path);
    },
  },
  components: {
    "no-layout": NoLayout,
    "main-layout": MainLayout,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
$body-font-family: "Open Sans";
$title-font: "Open Sans";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  background: #f1f1f1;
}

.v-application {
  font-family: $body-font-family, sans-serif !important;
  font-weight: 500;
  .title,
  .v-card__title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
    font-weight: bold;
  }
}

.click-table {
  tbody tr {
    cursor: pointer !important;
  }
}
</style>
