import Vue from "vue";
import Vuetify from "vuetify";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

const vuetify = new Vuetify();

Vue.use(Vuetify);

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});

const opts = {
  /*theme: {
    themes: {
      light: {
        primary: "#f54bac",
        secondary: "#0d2b59",
        accent: "#2797f4",
        error: "#b71c1c",
      },
    },
  },*/
};

export default new Vuetify(opts);
